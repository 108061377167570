<template>
  <div class="content page-box">
    <section class="content-box">
      <!-- 合同变更信息 -->
      <div class="detail-card">
        <header class="card-header hasbtn card-header-info ">
          <h4 class="second-title">合同变更信息</h4>
          <button
            class="btn btn-lucency"
            @click="goMainCon"
          >查看主合同</button>
        </header>
        <section class="card-content">
          <!-- 主合同信息 -->
          <el-row
            :gutter="20"
            type="flex"
            align="normal"
          >
            <el-col :span="6">
              <div class="item">
                <label class="label">合同变更类型:</label>
                <p class="text">{{ agreeInfo.typeName }}</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">主合同名称:</label>
                <p class="text">{{ agreeInfo.contract.contractName }}</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">主合同编号:</label>
                <p class="text">{{ agreeInfo.contract.contractNo }}</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">主合同类型:</label>
                <p class="text">{{ agreeInfo.contract.contractType }}</p>
              </div>
            </el-col>
          </el-row>
          <!-- 经办人、名称、编号、经办日期 -->
          <el-row
            :gutter="20"
            type="flex"
            align="normal"
          >
            <el-col :span="6">
              <div class="item">
                <label class="label">{{ agreeInfo.type == 'SIDE_AGREEMENT'? '补充协议经办人:' : agreeInfo.type == 'RELEASE_AGREEMENT'? '解除协议经办人:' : '主体变更经办人:' }}</label>
                <p class="text">{{ agreeInfo.operatorName }}</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">{{ agreeInfo.type == 'SIDE_AGREEMENT'? '补充协议名称:' : agreeInfo.type == 'RELEASE_AGREEMENT'? '解除协议名称:' : '主体变更名称:' }}</label>
                <p class="text">{{ agreeInfo.agreementName }}</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">{{ agreeInfo.type == 'SIDE_AGREEMENT'? '补充协议编号:' : agreeInfo.type == 'RELEASE_AGREEMENT'? '解除协议编号:' : '主体变更编号:' }}</label>
                <p class="text">{{ agreeInfo.agreementNo }}</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">{{ agreeInfo.type == 'SIDE_AGREEMENT'? '补充协议经办日期:' : agreeInfo.type == 'RELEASE_AGREEMENT'? '解除协议经办日期:' : '主体变更经办日期:' }}</label>
                <p class="text">{{ agreeInfo.modifyDateStr }}</p>
              </div>
            </el-col>
          </el-row>
          <!-- 补充协议-是否需要付款 -->
          <el-row
            v-if="agreeInfo.type == 'SIDE_AGREEMENT'"
            :gutter="20"
            type="flex"
            align="normal"
          >
            <el-col :span="6">
              <div class="item">
                <label class="label">是否需要付款:</label>
                <p class="text">
                  {{ agreeInfo.needPay == "Y" ? "需要" : "不需要" }}
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">是否供方合同:</label>
                <p class="text">
                  {{ agreeInfo.supplyStoreFlag == "0" ? "否" : "是" }}
                </p>
              </div>
            </el-col>
          </el-row>
          <!-- 主体变更-签约主体 -->
          <!-- <el-row
            v-if="agreeInfo.type == 'SUBJECT_CHANGE'"
            :gutter="20"
            type="flex"
            align="normal"
          >
            <el-col :span="12">
              <div class="item">
                <label class="label">原我方签约主体:</label>
                <p class="text">{{ agreeInfo.contract.ourCompanyList | ArrayToString }}</p>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="item">
                <label class="label">现我方签约主体:</label>
                <p class="text">{{ agreeInfo.ourCompanyList | ArrayToString }}</p>
              </div>
            </el-col>
          </el-row>
          <el-row
            v-if="agreeInfo.type == 'SUBJECT_CHANGE'"
            :gutter="20"
            type="flex"
            align="normal"
          >
            <el-col :span="12">
              <div class="item">
                <label class="label">原对方签约主体:</label>
                <p class="text">{{ agreeInfo.contract.supplierCompanyList | ArrayToString }}</p>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="item">
                <label class="label">现对方签约主体:</label>
                <p class="text">{{ agreeInfo.supplierCompanyList | ArrayToString }}</p>
              </div>
            </el-col>
          </el-row> -->
          <!-- 是否供方合同 -->
          <el-row
            :gutter="20"
            type="flex"
            align="normal"
            v-if="agreeInfo.type == 'SUBJECT_CHANGE'"
          >
            <el-col :span="6">
              <div class="item">
                <label class="label">是否供方合同:</label>
                <p class="text">
                  {{ agreeInfo.supplyStoreFlag == "0" ? "否" : "是" }}
                </p>
              </div>
            </el-col>
          </el-row>
        </section>

      </div>
      <!-- 主体变更-签约主体 -->
      <div class="detail-card" v-if="agreeInfo.type == 'SUBJECT_CHANGE'">
        <header class="card-header card-header-info">
          <h4 class="second-title">签约主体</h4>
        </header>
        <section class="card-content">
          <div class="qy-body-flex-wrapper">
            <!-- 变更前主体 -->
            <div class="qy-body-item gray">
              <h2 class="qy-body-item-title">变更前</h2>
              <ul class="qy-body-item-detail">
                <li class="qy-body-item-detail-li">
                  <span class="title">我方签约主体：</span>
                  <ul>
                    <li v-if="!(agreeInfo.contract.ourCompanyList && agreeInfo.contract.ourCompanyList.length)">-</li>
                    <li v-for="(item, index) in agreeInfo.contract.ourCompanyList" :key="index">{{item.name}}</li>
                  </ul>
                </li>
                <li class="qy-body-item-detail-li">
                  <span class="title">对方签约主体：</span>
                  <ul>
                    <li v-if="!(agreeInfo.contract.supplierCompanyList &&  agreeInfo.contract.supplierCompanyList.length)">-</li>
                    <li v-for="(item, index) in agreeInfo.contract.supplierCompanyList" :key="index">
                      {{item.name}}
                  <span v-show="item.type === 'T04'" v-supplier>{{item.supplierSourceName || '供方'}}</span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <!-- 变更后主体 -->
            <div class="qy-body-item pink">
              <h2 class="qy-body-item-title">变更后</h2>
              <ul class="qy-body-item-detail">
                <li class="qy-body-item-detail-li">
                  <span class="title">我方签约主体：</span>
                  <ul>
                    <li v-if="!(agreeInfo.ourCompanyList && agreeInfo.ourCompanyList.length)">-</li>
                    <li v-for="(item, index) in agreeInfo.ourCompanyList" :key="index">{{item.name}}</li>
                  </ul>
                </li>
                <li class="qy-body-item-detail-li">
                  <span class="title">对方签约主体：</span>
                  <ul>
                    <li v-if="!(agreeInfo.supplierCompanyList && agreeInfo.supplierCompanyList.length)">-</li>
                    <li v-for="(item, index) in agreeInfo.supplierCompanyList" :key="index">
                      {{item.name}}
                  <span v-show="item.type === 'T04'" v-supplier>{{item.supplierSourceName || '供方'}}</span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      <!-- 补充协议-主体增加 -->
      <div
        class="detail-card"
        v-if="agreeInfo.type == 'SIDE_AGREEMENT'"
      >
        <header class="card-header card-header-info ">
          <h4 class="second-title">补充协议签约主体</h4>
        </header>
        <section class="card-content">
          <div class="qy-body-flex-wrapper">
            <!-- 变更前主体 -->
            <div class="qy-body-item pink">
              <h2 class="qy-body-item-title">我方签约主体</h2>
              <ul class="qy-body-item-detail">
                <li class="qy-body-item-detail-li">
                  <ul>
                    <li v-if="!(agreeInfo.ourCompanyList && agreeInfo.ourCompanyList.length)">-</li>
                    <li v-for="(item, index) in agreeInfo.ourCompanyList" :key="index">{{item.name}}</li>
                  </ul>
                </li>
              </ul>
            </div>
            <!-- 变更后主体 -->
            <div class="qy-body-item pink">
              <h2 class="qy-body-item-title">对方签约主体</h2>
              <ul class="qy-body-item-detail">
                <li class="qy-body-item-detail-li">
                  <ul>
                    <li v-if="!(agreeInfo.supplierCompanyList && agreeInfo.supplierCompanyList.length)">-</li>
                    <li v-for="(item, index) in agreeInfo.supplierCompanyList" :key="index">
                      {{item.name}}
                  <span v-show="item.type === 'T04'" v-supplier>{{item.supplierSourceName || '供方'}}</span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <!-- <el-row
            :gutter="20"
            type="flex"
            align="normal"
          >
            <el-col :span="12">
              <div class="item">
                <label class="label">我方签约主体</label>
                <p class="text">{{ agreeInfo.ourCompanyList | ArrayToString }}</p>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="item">
                <label class="label">对方签约主体:</label>
                <p class="text">{{ agreeInfo.supplierCompanyList | ArrayToString }}</p>
              </div>
            </el-col>
          </el-row> -->
        </section>
      </div>
      <!-- 解除协议-签约主体 -->
      <div class="detail-card" v-if="agreeInfo.type == 'RELEASE_AGREEMENT'">
        <header class="card-header card-header-info ">
          <h4 class="second-title">签约主体</h4>
        </header>
        <section class="card-content">
          <div class="qy-body-flex-wrapper">
            <!-- 模块一 -->
            <div class="qy-body-item pink">
              <h2 class="qy-body-item-title">我方签约主体</h2>
              <ul class="qy-body-item-detail">
                <li class="qy-body-item-detail-li">
                  <ul>
                    <li v-if="!(agreeInfo.ourCompanyList && agreeInfo.ourCompanyList.length)">-</li>
                    <li v-for="(item, index) in agreeInfo.ourCompanyList" :key="index">{{item.name}}</li>
                  </ul>
                </li>
              </ul>
            </div>
            <!-- 模块二 -->
            <div class="qy-body-item pink">
              <h2 class="qy-body-item-title">对方签约主体</h2>
              <ul class="qy-body-item-detail">
                <li class="qy-body-item-detail-li">
                  <ul>
                    <li v-if="!(agreeInfo.supplierCompanyList && agreeInfo.supplierCompanyList.length)">-</li>
                    <li v-for="(item, index) in agreeInfo.supplierCompanyList" :key="index">
                      {{item.name}}
                  <span v-show="item.type === 'T04'" v-supplier>{{item.supplierSourceName || '供方'}}</span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      <!-- 补充协议招采信息 -->
      <div
        class="detail-card"
        v-if="agreeInfo.type == 'SIDE_AGREEMENT'"
      >
        <header class="card-header card-header-info ">
          <h4 class="second-title">补充协议招采信息</h4>
        </header>
        <section class="card-content">
          <el-row
            :gutter="20"
            type="flex"
            align="normal"
          >
            <el-col :span="24">
              <div class="item">
                <label class="label">招标/比价/直委:</label>
                <p class="text">
                  {{ agreeInfo.orderMsg | empty }}
                </p>
              </div>
            </el-col>
          </el-row>
          <el-row
            :gutter="20"
            type="flex"
            align="normal"
            v-if="orderFile.length !== 0 "
          >
            <el-col :span="14">
              <div class="item">
                <label class="label">附件:</label>
                <div class="text">
                  <el-table
                    :show-header="false"
                    :data="orderFile"
                  >
                    <el-table-column
                      prop="fileName"
                      min-width="250"
                    >
                      <template slot-scope="scope">
                        <div>
                          <i class="el-icon-document"></i>
                          <a
                            @click="preview(scope.row, agreeInfo.contractId)"
                            class="link-name"
                          >{{ scope.row.fileName }}</a>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="createDate"
                      min-width="50"
                    >
                      <template slot-scope="scope">
                        <div class="light">
                          <span>{{ scope.row.bizCreateDateStr }}</span>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      align="center"
                      min-width="50"
                    >
                      <template slot-scope="scope">
                        <div class="orderDownload">
                          <span @click="download(scope.row)">下载</span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </el-col>
          </el-row>
        </section>
      </div>

      <!-- 补充协议金额 -->
      <div
        class="detail-card"
        v-if="agreeInfo.type == 'SIDE_AGREEMENT' && agreeInfo.needPay == 'Y'"
      >
        <header class="card-header card-header-info ">
          <h4 class="second-title">补充协议金额</h4>
        </header>
        <section class="card-content">
          <el-row
            :gutter="20"
            type="flex"
            align="normal"
          >
            <el-col :span="6">
              <div class="item">
                <label class="label" style="width:160px;">
                  <el-tooltip
                    effect="dark"
                    placement="bottom-start"
                    :offset="-10"
                  >
                    <div slot="content">
                      <p>金额追加类：指补充协议的协议金额为在主协议基础上追加的金额</p>
                      <p>金额替代类：指补充协议的协议金额会替代主协议的金额</p>
                      <p>金额不变类：指补充协议无需额外增加金额，仍按主协议的金额付款</p>
                    </div>
                    <img
                      src="../assets/img/information.png"
                      class="money-icon"
                      alt=""
                    />
                  </el-tooltip>
                  补充协议金额类型:
                </label>
                <p class="text">{{ { 1: '金额追加类', 2: '金额替代类',3:'金额不变类'}[agreeInfo.agreementAmountType] | empty }}</p>
              </div>
            </el-col>
          </el-row>
          <template v-if="agreeInfo.agreementAmountType != 3">
          <el-row
            :gutter="20"
            type="flex"
            align="normal"
          >
            <el-col :span="6">
              <div class="item">
                <label class="label">补充协议币种:</label>
                <p class="text">{{ agreeInfo.currency | empty }}</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label" style="width:160px;">
                  <el-tooltip
                    effect="dark"
                    content="指本次补充协议自身的签约金额"
                    placement="bottom-start"
                    :offset="-10"
                  >
                    <img
                      src="../assets/img/information.png"
                      class="money-icon"
                      alt=""
                    />
                  </el-tooltip>
                  本次补充协议金额(含税):
                </label>
                <p class="text">{{ agreeInfo.amount | millimeterFormat }}</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label" style="width:190px;">
                  <el-tooltip
                    effect="dark"
                    content="指本次补充协议签署后，主协议累计的合同总金额，即合同最大可付款金额"
                    placement="bottom-start"
                    :offset="-10"
                  >
                    <img
                      src="../assets/img/information.png"
                      class="money-icon"
                      alt=""
                    />
                  </el-tooltip>
                  主协议累计总金额(含税):
                </label>
                <p class="text">{{ String(agreeInfo.agreementAfterAmountTax || 0) | millimeterFormat }}</p>
              </div>
            </el-col>

            <el-col :span="6">
              <div class="item">
                <label class="label">发票类型:</label>
                <p class="text">{{ agreeInfo.invoiceTypeName | empty }}</p>
              </div>
            </el-col>
          </el-row>
          <el-row
            :gutter="20"
            type="flex"
            align="normal"
          >
            <el-col :span="6">
              <div class="item">
                <label class="label">可抵扣税额:</label>
                <p class="text">
                  {{ agreeInfo.deductibleTax | millimeterFormat | empty }}
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">可抵扣税率:</label>
                <p class="text">
                  {{ agreeInfo.deductibleTaxRate | formatRate }}
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label" style="width:190px;">
                  <el-tooltip
                    effect="dark"
                    content="指本次补充协议签署后，主协议累计的合同总金额（不含抵扣税）"
                    placement="bottom-start"
                    :offset="-10"
                  >
                    <img
                      src="../assets/img/information.png"
                      class="money-icon"
                      alt=""
                    />
                  </el-tooltip>
                  主协议累计总金额(不含抵扣税):
                </label>
                <p class="text">
                  {{ String(agreeInfo.agreementAfterAmountNoTax || 0) | millimeterFormat }}
                </p>
              </div>
            </el-col>
            <el-col :span="6" v-if="agreeInfo.aboveQuotaAmount || agreeInfo.aboveQuotaScale">
              <div class="item">
                <label class="label">可超额付款比例/金额:</label>
                <p
                  class="text"
                  v-if="agreeInfo.aboveQuotaType == 'FIX'"
                >
                  {{ agreeInfo.aboveQuotaAmount | millimeterFormat | empty }}
                </p>
                <p
                  class="text"
                  v-else
                >
                  {{ agreeInfo.aboveQuotaScale | formatRate }}
                </p>
              </div>
            </el-col>
          </el-row>
          </template>
        </section>
      </div>
      <!-- 说明 -->
      <div class="detail-card">
        <header class="card-header card-header-info ">
          <h4
            class="second-title"
            v-if="agreeInfo.type == 'SIDE_AGREEMENT'"
          >
            合同变更说明
          </h4>
          <h4
            class="second-title"
            v-if="agreeInfo.type == 'RELEASE_AGREEMENT'"
          >
            解除协议说明
          </h4>
          <h4
            class="second-title"
            v-if="agreeInfo.type == 'SUBJECT_CHANGE'"
          >
            主体变更说明
          </h4>
        </header>
        <section class="card-content">
          <el-row>
            <el-col :span="24">
              <div class="item">
                <label class="label">说明信息:</label>
                <p class="text">
                  {{ agreeInfo.agreementNote }}
                </p>
              </div>
            </el-col>
          </el-row>
        </section>
      </div>
      <!-- 费项分摊 -->
      <div
        class="detail-card"
        v-if="agreeInfo.type == 'SIDE_AGREEMENT' && agreeInfo.needPay == 'Y' "
      >
        <header class="card-header card-header-info ">
          <h4 class="second-title">费项分摊</h4>
        </header>
        <section class="card-content">
          <el-row>
            <el-table
              :data="agreeInfo.costExpenseList"
              ref="costDate"
              style="width: 100%"
              border
              fit
              :header-row-style="{ height: '36px' }"
            >
              <el-table-column
                v-for="(item, index) in costColumn"
                :key="index"
                :label="item.label"
                :prop="item.prop"
                :align="item.align"
              ></el-table-column>
              <el-table-column
                align="right"
                label="分摊金额(含税)"
              >
                <template slot-scope="scope">
                  {{ scope.row.amount | millimeterFormat | empty }}
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </section>
      </div>
      <!-- 附件 -->
      <div class="detail-card fileCard">
        <header class="card-header card-header-info file-header">
          <h4 class="second-title">附件</h4>
          <button
            class="btn btn-lucency"
            @click="downloadAll"
            v-if="fileDate.length !== 0"
          >
            下载全部附件
          </button>
        </header>
        <section class="card-content">
          <el-row>
            <el-table
              :data="fileDate"
              ref="fileDate"
              style="width: 100%"
              border
              fit
              :header-row-style="{ height: '36px' }"
            >
              <el-table-column
                label="附件"
                min-width="300"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  <i class="el-icon-document"></i>
                  <a
                    @click="preview(scope.row, agreeInfo.contractId)"
                    class="linkName"
                  >{{ scope.row.fileName }}</a>
                </template>
              </el-table-column>
              <el-table-column
                v-for="(item, index) in fileColumn"
                :key="index"
                :label="item.label"
                :align="item.align"
                :min-width="item.minWidth"
              >
              <template slot-scope="scope">
                <el-popover trigger="hover" placement="top" v-if="scope.row.fileNote && item.prop === 'fileNote' && scope.row.fileNote.length >= 66">
                  <p>{{ scope.row[item.prop] }}</p>
                  <div slot="reference" class="name-wrapper">
                    {{ scope.row[item.prop].slice(0, 66) }}{{ '...' }}
                  </div>
                </el-popover>
                <span v-else>{{ scope.row[item.prop] }}</span>
              </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="left"
                min-width="60"
              >
                <template slot-scope="scope">
                  <div class="fileDownload">
                    <span @click="download(scope.row)"> 下载 </span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </section>
      </div>
    </section>
    <ImageViewer @closeViewer="closeViewer" v-if="showImageViewer" :urls="imageUrl"></ImageViewer>
  </div>
</template>
<script>
import axios from "axios";
import baseurl from "../api/baseUrl.js";
import preview from './mixins/preview'
export default {
  name: "businessInfo_Agree",
  data() {
    return {
      agreeInfo: {
        attachFileList:[],
        contract: {}
      },
      // 表头列表
      costColumn: [
        {
          label: "责任人",
          prop: "operatorName",
        },
        {
          label: "费项",
          prop: "costItemsName",
        },
        {
          label: "预算分摊部门",
          prop: "costItemsDeptName",
        },
      ],
      // 补充协议-招采附件
      orderFile: [],
      // 附件列表
      fileDate: [],
      // 附件表头
      fileColumn: [
        {
          label: "备注",
          prop: "fileNote",
          minWidth: 500,
        },
        {
          label: "上传时间",
          prop: "bizCreateDateStr",
          minWidth: 180,
        },
      ],
      Token: "",
    };
  },
  mixins: [preview],
  methods: {
    // 根据路由获取参数
    getUrl() {
      return new Promise((resolve) => {
        let params = this.$route.params;
        this.instanceId = params.id;
        this.Token = params.Token;
        resolve(params);
      });
    },
    // 获取详细信息
    getAgreeInfo(argument) {
      let baseUrl = baseurl + "/cms/inner"
      let instanceId = argument.id
      let Token = argument.Token
      this.Token = Token
      let params = {
        instanceId,
      }
      axios
        .post(
          baseUrl + "/contractAgreement/getAgreementInfo",
          { params },
          {
            headers: {
              "Content-Type": "application/json",
              "Token": Token
            },
          }
        )
        .then((res) => {
          if (res.status !== 200) return;
          if (res.data.code !== "200") {
            if (res.data.code == "303" || res.data.code == "500") {
              this.$message({
                type: "error",
                message: "Token过期，请退出重新登陆",
              });
              return;
            }
            this.$message({
              type: "error",
              message: "找不到该合同信息！",
            });
            return;
          }

          let data = res.data.data;

          this.agreeInfo = data

          let fileList = data.attachFileList

          this.orderFile = fileList.filter(item => {
            return item.fileCategory == "RECRUIT";
          })

          this.fileDate = fileList.filter(item => {
            return item.fileCategory == "CONTRACT";
          })
        });
    },
    // 下载附件
    download(row) {
      let data = [{ "fileId": row.docLibId, "contractId": this.agreeInfo.contractId }]
      let baseUrl = baseurl + "/cms/inner";
        let Token = this.Token;
      axios
        .post(
          baseUrl + "/file/fileDownload",
          { data },
          {
            headers: {
              "Content-Type": "application/json",
              "Token": Token
            },
          }
        )
        .then((res) => {
          if (res.status !== 200) return;
          if (res.data.code !== "200") {
            if (res.data.code == "303" || res.data.code == "500") {
              this.$message({
                type: "error",
                message: "Token过期，请退出重新登陆",
              });
              return;
            }
            this.$message({
              type: "error",
              message: "请求错误",
            });
            return;
          }
          const iframe = document.createElement("iframe");
          iframe.style.display = "none";
          iframe.style.height = 0;
          iframe.src = res.data.data
          document.body.appendChild(iframe);
          setTimeout(()=>{
            iframe.remove();
          }, 5 * 60 * 1000);
          })
    },
    // 下载所有附件
    downloadAll() {
      this.agreeInfo.attachFileList.forEach( item => {
        let data = [{ "fileId": item.docLibId, "contractId": this.agreeInfo.contractId }]
        let baseUrl = baseurl + "/cms/inner";
        let Token = this.Token;
        axios
          .post(
            baseUrl + "/file/fileDownload",
            { data },
            {
              headers: {
                "Content-Type": "application/json",
                "Token": Token
              },
            }
          )
          .then((res) => {
            if (res.status !== 200) return;
            if (res.data.code !== "200") {
              if (res.data.code == "303" || res.data.code == "500") {
                this.$message({
                  type: "error",
                  message: "Token过期，请退出重新登陆",
                });
                return;
              }
              this.$message({
                type: "error",
                message: "请求错误",
              });
              return;
            }
            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.style.height = 0;
            iframe.src = res.data.data
            document.body.appendChild(iframe);
            setTimeout(()=>{
              iframe.remove();
            }, 5 * 60 * 1000);
            })
      })
    },

    // 跳转主合同页面
    goMainCon() {
      let old_envs = {
        prod: 'https://invest.wu-capital.com/#/manage/contractNew?Referer=%2Fcondetail%2F' + this.agreeInfo.contractId,
        uat: 'https://invest-uat-xjp.wu-capital.com/#/manage/contractNew?Referer=%2Fcondetail%2F' + this.agreeInfo.contractId,
        // uat: 'https://invest-uat-xjp.wu-capital.com/#/manage/contractNew?Referer=%2Fcondetail%2F' + this.agreeInfo.contractId
      }
      let envs = {
        prod: 'https://portal-usa.platanus.com/#/manage/contractNew?Referer=%2Fcondetail%2F' + this.agreeInfo.contractId,
        uat: 'https://portal-uat-usa.platanus.com/#/manage/contractNew?Referer=%2Fcondetail%2F' + this.agreeInfo.contractId,
        dev: 'https://portal-dev.platanus.com/#/manage/contractNew?Referer=%2Fcondetail%2F' + this.agreeInfo.contractId
      }

      let url
      if (this.$settings.isUAT) {
        url =  envs.uat
      } else if (this.$settings.isDev) {
        url =  envs.dev
      } else {
        url =  envs.prod
      }
      window.open(url)
      // if ("shcms.wu-capital.com" === location.host) {
      //   // 生产
      //   window.open('https://invest.wu-capital.com/#/manage/contractNew?Referer=%2Fcondetail%2F' + this.agreeInfo.contractId)
      // } else if ("cms-uat.wu-capital.com" === location.host) {
      //   // uat
      //   window.open('https://invest-uat-xjp.wu-capital.com/#/manage/contractNew?Referer=%2Fcondetail%2F' + this.agreeInfo.contractId)
      // }
      // 数据迁移的主合同请去合同平台查看审批信息
      // if (!this.agreeInfo.contract.bpminnerUrl) {
      //   this.$message.error('请在合同平台查看数据迁移的主合同！')
      //   return
      // }
      // window.open(this.agreeInfo.contract.bpminnerUrl);
    },
    getCurrentUser(params) {
      console.log(params, '1111222223333344444')
      let Token = params.Token
      this.$request.post("/employee/getCurrUser", {}, {
        headers: {
          "Content-Type": "application/json",
          "Token": Token
        },
      }).then(res =>{
        console.log(res, 'sssssssssssssssss')
        if(res.code === '200' && res.data) {
          this.currentUser = res.data || {}
        }
      })
    }
  },
  created() {
    this.getUrl().then((res) => {
      this.getCurrentUser(res)
      this.getAgreeInfo(res);
    });
  },
};
</script>

<style lang="less" scoped>
.page-box {
  .detail-card {
    .hasbtn {
      display: flex;
      justify-content: space-between;
    }

    .card-content {
      .money-icon{
        // vertical-align: text-bottom;
        vertical-align: text-top;
        cursor: pointer;
      }
      .el-row {
        margin-top: 12px;
      }

      .link-name {
        color: #997236;
        margin-left: 12px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .orderDownload {
      border-left: 1px solid #e5e5e5;
      span {
        color: #997236;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.linkName{
  margin-left: 10.5px;
}
</style>
<style lang="less">
.el-tooltip__popper{
  max-width: 100% !important;
  width: 300px;
}
.el-popover{
  background: rgba(50,50,50,0.95);
  color: #fff;
  max-width: 95%;
}
.popper__arrow::after{
  border-top-color: rgba(50,50,50,0.95) !important;
}
</style>
